import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production && environment.datadog) {
    datadogRum.init(environment.datadog as RumInitConfiguration);
}

if (
    environment.production &&
    environment.googleTagID !== '' &&
    environment.clarity !== ''
) {
    // add Google Analytics script to <head>
    if (environment.googleTagID !== '') {
        const firstScript = document.createElement('script');
        firstScript.async = true;
        firstScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagID}`;
        document.head.appendChild(firstScript);

        const script = document.createElement('script');
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${environment.googleTagID}');
    gtag('get', '${environment.googleTagID}', 'client_id', (clientID) => {
    });`;
        document.head.appendChild(script);
    }

    if (environment.clarity !== '') {
        const scriptClarity = document.createElement('script');
        scriptClarity.type = 'text/javascript';
        scriptClarity.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${environment.clarity}");`;
        document.head.appendChild(scriptClarity);
    }
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
