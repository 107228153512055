import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { constantes } from '../enums/constantes';
import { hostsConfig } from '../enums/hosts';
import { defaultTechnos } from '../models/techno';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  providers : string[];
  shapes : string[];
  hosts : string [];
  tools : string [];
  others : string[];

  private iconRegistry : MatIconRegistry = inject(MatIconRegistry);
  private domSanitizer : DomSanitizer = inject(DomSanitizer);

  constructor(){
    this.providers = [...constantes.OAUTH_PROVIDERS, ...constantes.GIT_PROVIDERS]
    this.shapes = [...constantes.SHAPES]
    this.addDefaultIcons();
  }

  addDefaultIcons(){
    this.tools =  ["datadog", "lighthouse", "infisical", "apasix"];
    this.others = ["feuille"];

    for(const provider of this.providers){
      this.iconRegistry.addSvgIcon(provider, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/logos/${provider}.svg`) );
    }
    for(const shape of this.shapes){
      this.iconRegistry.addSvgIcon(shape, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${shape}.svg`) );
    }
    for(const other of this.others){
      this.iconRegistry.addSvgIcon(other, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${other}.svg`) );
    }
    for(const tool of this.tools){
      this.iconRegistry.addSvgIcon(tool, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/logos/tools/${tool}.svg`) );
    }
    for(const [technoId, techno] of defaultTechnos){
      this.iconRegistry.addSvgIcon(techno.name, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/logos/technos/${techno.name.toLowerCase().replace(".", "-")}.svg`) );
    }
    for(const host in hostsConfig){
      this.iconRegistry.addSvgIcon(hostsConfig[host].icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/logos/hosts/${hostsConfig[host].icon}.svg`) );
    }
  }



}
