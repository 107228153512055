import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { template } from '../data/data-source';
import { cloudProviders } from '../enums/cloudprovider';
import { BASE_CONTEXTS, contexts } from '../enums/contexts';
import { technos } from '../enums/technos';
import { Action } from '../models/action';
import { GitProvider } from '../models/git-provider';
import { GitRepo } from '../models/git-repo';
import { Project } from '../models/project';
import { Commit, deploymentStates, Role, Service } from '../models/service';
import { StackData } from '../models/stack';
import { defaultTechnos, Techno } from '../models/techno';
import { ApiServiceInterface } from './api.service';
import AvatarGenerator from './avatar.service';
import { logAction } from './logs-action';

const log = logAction;
const users = [
    {
        firstName: 'loïc',
        lastName: 'DECHAMPS',
        email: 'loic.d@webcapsule.io',
        avatar: '', // not fixed
        emailVerified: true,
        enabled: true,
        createdTimestamp: new Date('2023-06-03T12:24:00'), // convert to timestamp
        role: 'Owner', // not fixed
        rights: 'Edit all environments', // not fixed
    },
    {
        firstName: 'Arthur',
        lastName: 'ROUZOUL',
        email: 'arthur@webcapsule.io',
        avatar: '',
        emailVerified: true,
        enabled: true,
        createdTimestamp: new Date('2023-06-10T14:25:00'),
        role: 'Contributor',
        rights: 'Staging environment',
        roleByService: {
            [technos.DIRECTUS]: 'Production & Staging',
            [technos.WEWEB]: 'Production & Staging',
            [technos.N8N]: 'Production & Staging',
        },
    },
    {
        firstName: 'Antoine',
        lastName: 'DUSSARPS',
        email: 'antoine@webcapsule.io',
        avatar: '',
        emailVerified: true,
        enabled: true,
        createdTimestamp: new Date('2023-06-20T20:35:00'),
        role: 'Contributor',
        rights: 'Staging environment',
        roleByService: {
            [technos.DIRECTUS]: 'None',
            [technos.WEWEB]: 'None',
            [technos.N8N]: 'None',
        },
    },
    {
        firstName: 'Louis',
        lastName: 'DUSSARPS',
        email: 'louis@webcapsule.io',
        avatar: '',
        emailVerified: true,
        enabled: true,
        createdTimestamp: new Date('2023-07-03T12:47:00'),
        role: 'Viewer',
        rights: 'None',
        roleByService: {
            [technos.DIRECTUS]: 'None',
            [technos.WEWEB]: 'None',
            [technos.N8N]: 'None',
        },
    },
];

const userProfile = {
    1: {
        letter: 'LDe',
        color: '#38D4CC',
    },
    2: {
        letter: 'LDu',
        color: '#FF9960',
    },
    3: {
        letter: 'AD',
        color: '#7C8AE8',
    },
    4: {
        letter: 'AR',
        color: '#FFF176',
    },
};

const deployment = {
    currentCommit: 'Last',
    deployedBranch: 'branch1',
    message: 'toto',
    deployedAt: new Date(),
};

const context = {
    deployment: {
        currentCommit: 'Last',
        deployedBranch: 'branch1',
        message: 'toto',
        deployedAt: new Date(),
    },
    deployments: [
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.ERROR,
            message: 'nouveau',
        },
        {
            newCommit: 'Last',
            oldCommit: 'Last',
            state: deploymentStates.SUCCESS,
            message: "we can't because they were a failure in the system",
        },
    ],
    mainCycle: {
        message: 'tutu',
        state: deploymentStates.SUCCESS,
    },
    commits: [
        {
            ctxtToDeploy: 'prod',
            createdAt: new Date('2023-12-17T03:24:00'),
            message: '[Impl] Text in red',
            commitId: 'Last',
            author: {
                name: 'louis',
                email: 'louis@webcapsule.io',
                date: '1695401093 +0200',
            },
        },
    ], // commit
    infra: {},
    reports: [
        {
            _id: '12',
            createdAt: new Date('2024-01-15T10:24:00'),
        },
    ],
    domains: ['api.staging.main.ocirp.webcapsule.io'],
};

let directusContext = Object.assign({}, context);
directusContext.domains = ['api.staging.main.ocirp.webcapsule.io'];

let n8nContext = Object.assign({}, context);
n8nContext.domains = ['workflow.staging.main.ocirp.webcapsule.io'];

const roles: Role[][] = [
    //mock only
    [
        {
            userId: 1,
            user: {
                name: 'Louis',
                email: 'louis@webcapsule.io',
                date: '1695401093 +0200',
            },
            roleByService: {
                [technos.DIRECTUS]: ['Staging'],
                [technos.WEWEB]: ['Staging'],
                [technos.N8N]: ['Staging'],
            },
            services: [12],
        },
    ],
    [
        {
            userId: 1,
            user: {
                name: 'Antoine',
                email: 'antoine@webcapsule.io',
                date: '1695401093 +0200',
            },
            roleByService: {
                [technos.DIRECTUS]: ['Staging', 'Production'],
                [technos.WEWEB]: ['Staging', 'Production'],
                [technos.N8N]: ['Staging', 'Production'],
            },
            services: [13, 14],
        },
        {
            userId: 2,
            user: {
                name: 'Louis',
                email: 'louis@webcapsule.io',
                date: '1695401093 +0200',
            },
            roleByService: {
                [technos.DIRECTUS]: ['Staging'],
                [technos.WEWEB]: ['Staging'],
                [technos.N8N]: ['Staging'],
            },
            services: [13],
        },
        {
            userId: 3,
            user: {
                name: 'Arthur',
                email: 'arthur@webcapsule.io',
                date: '1695401093 +0200',
            },
            roleByService: {
                [technos.DIRECTUS]: ['Staging', 'Production'],
                [technos.WEWEB]: ['Staging', 'Production'],
                [technos.N8N]: ['Staging', 'Production'],
            },
            services: [14],
        },
        {
            userId: 4,
            user: {
                name: 'Loïc',
                email: 'loic.d@webcapsule.io',
                date: '1695401093 +0200',
            },
            roleByService: {
                [technos.DIRECTUS]: [],
                [technos.WEWEB]: [],
                [technos.N8N]: [],
            },
            services: [13, 14],
        },
    ],
];

let projects = [
    {
        _id: 1,
        cloudProvider: 'aws',
        cloudEnv: {
            region: 'eu-west-3',
        },
        domains: ['webcapsule.io'],
        projectName: 'Landing Page',
        picture: 'phone', //
        description: 'blablabla',
        roles: roles[1],
        services: [
            {
                _id: 12,
                techno: { id: technos.ANGULAR },
                git: { name: 'T3', projectName: 'T3' },
                projectName: 'Landing Page',
                roles: roles[0],
                gitWBCE: { name: 'T3WBCE' },
                currentContext: 'staging',
                state: deploymentStates.SUCCESS,
                [contexts.PRODUCTION]: context,
                [contexts.STAGING]: context,
                //        [contexts.PREVIEW] : context
            },
        ],
    },
    {
        _id: 2,
        cloudProvider: 'ovh',
        domains: ['webcapsule.io'],
        projectName: 'Business Automation',
        picture: 'crm', //
        description: 'blablabla',
        cloudEnv: {
            region: 'eu-west-3',
        },
        roles: roles[1],
        services: [
            {
                _id: 14,
                state: deploymentStates.SUCCESS,
                techno: { id: technos.DIRECTUS },
                gitWBCE: { name: 'T3WBCE' },
                currentContext: 'staging',
                [contexts.PRODUCTION]: directusContext,
                [contexts.STAGING]: directusContext,
                //       [contexts.PREVIEW] : directusContext
            },
            {
                _id: 13,
                state: deploymentStates.SUCCESS,
                techno: { id: technos.WEWEB },
                gitWBCE: { name: 'T3WBCE' },
                currentContext: 'staging',
                [contexts.PRODUCTION]: context,
                [contexts.STAGING]: context,
                //     [contexts.PREVIEW] : context
            },
            {
                _id: 15,
                state: deploymentStates.SUCCESS,
                techno: { id: technos.N8N },
                gitWBCE: { name: 'T3WBCE' },
                currentContext: 'staging',
                [contexts.PRODUCTION]: n8nContext,
                [contexts.STAGING]: n8nContext,
                //     [contexts.PREVIEW] : n8nContext
            },
        ],
    },
];

const newProject = {
    _id: 3,
    projectName: 'Landing Page v2',
    deployment: true, //mock
    cloudEnv: {
        region: 'eu-west-3',
    },
    domains: ['webcapsule.io'],
    cloudProvider: 'aws',
    picture: 'crm', //
    description: 'blablabla',
    roles: roles[1],
    services: [
        {
            _id: 12,
            state: deploymentStates.SUCCESS,
            techno: { id: technos.ANGULAR },
            git: { name: 'T3', projectName: 'T3' },
            projectName: 'Landing Page v2',
            roles: roles[0],
            gitWBCE: { name: 'T3WBCE' },
            currentContext: 'staging',
            [contexts.PRODUCTION]: context,
            [contexts.STAGING]: context,
        },
    ],
};

const userInfo = {
    _id: 1,
    given_name: 'Loîc',
    family_name: 'Dechamps',
    username: 'ldechamps',
    email: 'loic@webcapsule.io',
    active: true,
    email_verified: false,
    name: 'Loic Dechamps',
};

const accounts = [
    // mock
    {
        _id: 1,
        name: 'My AWS account',
        provider: cloudProviders.AWS,
    },
    {
        _id: 2,
        name: 'My GCP account',
        provider: cloudProviders.GCP,
    },
    {
        _id: 3,
        name: 'My OVH account',
        provider: cloudProviders.OVH,
    },
];

const currentUser = {
    _id: 1,
    auth: {
        oauth: [
            {
                provider: 'gitlab',
                mail: 'ldechamps@hotmail.com',
            },
            {
                provider: 'github',
                mail: 'loic.dechamps@gmail.com',
            },
        ],
    },
    projects: projects,
};

interface DeploymentTemplate {
    ctxtToDeploy: string;
    createdAt: string;
    message: string;
    commitId: string;
    author?: {
        email?: string;
        name?: string;
        date?: string;
    };
}

let deploymentTemplate: DeploymentTemplate = {
    ctxtToDeploy: 'staging',
    createdAt: Date(),
    message: 'Default',
    commitId: 'commitId',
};

let deployments = [];
let commits = [];
let serviceId = '';

for (const context of BASE_CONTEXTS) {
    commits = [];
    serviceId = '14';

    deploymentTemplate.ctxtToDeploy = context;

    if (context === contexts.STAGING) {
        deploymentTemplate.message = '[Corr] Change survey.js template';
        (deploymentTemplate.commitId = 'commitId'),
            (deploymentTemplate.createdAt = new Date(
                '2024-01-15T08:24:00'
            ).toString()),
            (deploymentTemplate.author = {
                name: 'Antoine',
                email: 'antoine@webcapsule.io',
                date: '1695401093 +0200',
            }),
            commits.push(Object.assign({}, deploymentTemplate));
    }

    deploymentTemplate.message = '[Impl] Add survey.js plugin';
    deploymentTemplate.commitId = 'Last';
    (deploymentTemplate.createdAt = new Date('2024-01-13T10:28:00').toString()),
        (deploymentTemplate.author = {});
    commits.push(Object.assign({}, deploymentTemplate));

    deploymentTemplate.message = '[New] Initialization';
    deploymentTemplate.commitId = 'commitId2';
    (deploymentTemplate.createdAt = new Date('2024-01-10T11:34:00').toString()),
        commits.push(Object.assign({}, deploymentTemplate));

    deployments.push({
        serviceId: serviceId,
        commits: commits,
        context: context,
    });
    commits = [];
    serviceId = '13';

    deploymentTemplate.message = '[Impl] Add Validation Button';
    deploymentTemplate.commitId = 'Last2';
    (deploymentTemplate.createdAt = new Date('2024-01-20T08:24:00').toString()),
        commits.push(Object.assign({}, deploymentTemplate));

    deploymentTemplate.message = '[New] Initialization';
    deploymentTemplate.commitId = 'commitId';
    (deploymentTemplate.createdAt = new Date('2024-01-10T11:36:00').toString()),
        commits.push(Object.assign({}, deploymentTemplate));

    deployments.push({
        serviceId: serviceId,
        commits: commits,
        context: context,
    });
    commits = [];
    serviceId = '15';

    deploymentTemplate.message = '[Impl] Add ChatGPT node';
    deploymentTemplate.commitId = 'Last';
    (deploymentTemplate.createdAt = new Date('2024-01-25T19:15:00').toString()),
        (deploymentTemplate.author = {
            name: 'Louis',
            email: 'louis@webcapsule.io',
            date: '1695401093 +0200',
        }),
        commits.push(Object.assign({}, deploymentTemplate));

    deploymentTemplate.message = '[New] Initialization';
    deploymentTemplate.commitId = 'commitId';
    (deploymentTemplate.createdAt = new Date('2024-01-10T11:38:00').toString()),
        (deploymentTemplate.author = {});
    commits.push(Object.assign({}, deploymentTemplate));

    deployments.push({
        serviceId: serviceId,
        commits: commits,
        context: context,
    });

    commits = [];
    serviceId = '12';

    deploymentTemplate.message = '[New] Initialization';
    deploymentTemplate.commitId = 'commitId';
    commits.push(Object.assign({}, deploymentTemplate));

    deploymentTemplate.message = '[Impl] Add Validation Button';
    deploymentTemplate.commitId = 'Last';
    commits.push(Object.assign({}, deploymentTemplate));

    deployments.push({
        serviceId: serviceId,
        commits: commits,
        context: context,
    });
    commits = [];
}

const actions = [
    {
        // from dev
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:05:49.280Z',
            lastActivity: '2023-10-01T17:55:49.204Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '650738ffdeb0799f6b88947e',
        state: 2,
        bag: {
            currentStepIndex: 1,
            actions: {
                '650738ffdeb0799f6b88949f': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1],
            lastWaitId: '650738ffdeb0799f6b88949f',
        },
        actionRef: 'N8NMainCycle',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '15',
            context: 'staging',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '15',
        },
        workflowId: '65072fa49a1c266e137baff9',
        workflowStep: 4,
        createdAt: '2023-09-17T17:35:59.368Z',
        updatedAt: '2023-10-01T17:55:49.308Z',
        stateUpdatedAt: '2023-09-17T17:35:59.574Z',
        lockedAt: '2023-09-17T17:35:59.369Z',
        __v: 1,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:05:49.097Z',
            lastActivity: '2023-10-01T17:55:48.970Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '65072fa49a1c266e137baff9',
        state: 2,
        bag: {
            currentStepIndex: 9,
            actions: {
                '650738ffdeb0799f6b88947e': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1, 3, 5, 7, 9],
            isRollBackPossible: true,
        },
        actionRef: 'N8NMainWorkflow',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '15',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '15',
        },
        createdAt: '2023-09-17T16:56:04.223Z',
        updatedAt: '2023-10-01T17:55:49.202Z',
        stateUpdatedAt: '2023-09-17T17:35:59.319Z',
        lockedAt: '2023-09-17T16:56:04.225Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:56:10.389Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fa5deb0799f6b885979',
        state: 6,
        bag: {
            currentStepIndex: -1,
            actions: {},
            stepsHistory: [0, 1],
            isRollBackPossible: true,
        },
        actionRef: 'createWBCERepoAndSave',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '15',
        },
        result: [{}],
        filter: {
            projectId: '2',
            serviceId: '15',
        },
        workflowId: '65072fa49a1c266e137baff9',
        workflowStep: 0,
        createdAt: '2023-09-17T16:56:06.094Z',
        updatedAt: '2023-09-18T16:56:10.473Z',
        stateUpdatedAt: '2023-09-18T16:56:10.448Z',
        lockedAt: '2023-09-17T16:56:06.094Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': 180000,
        },
        cronActivity: {
            pending: false,
            frequency: 60000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:56:09.103Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fa7deb0799f6b8859ca',
        state: 6,
        bag: {},
        actionRef: 'createWBCERepo',
        nTimes: 0,
        argument: {
            repositoryName: '15',
        },
        result: {
            gitId: '24bb9c9f-c9ff-4973-a3b9-f6e171d6491a',
            projectName: '15',
        },
        filter: {
            projectId: '2',
            serviceId: '15',
        },
        workflowId: '65072fa5deb0799f6b885979',
        workflowStep: 0,
        createdAt: '2023-09-17T16:56:07.360Z',
        updatedAt: '2023-09-18T16:56:09.191Z',
        stateUpdatedAt: '2023-09-18T16:56:09.160Z',
        lockedAt: '2023-09-17T16:56:07.360Z',
        __v: 3,
    },
    {
        delays: {
            '1': 120000,
            '2': 600000,
        },
        cronActivity: {
            pending: false,
            frequency: 86400000,
            nextActivity: '2023-10-01T18:04:00.833Z',
            lastActivity: '2023-10-01T17:54:00.734Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '650738ffdeb0799f6b88949f',
        state: 2,
        bag: {
            inputs: [],
        },
        actionRef: 'WaitForInput',
        nTimes: 0,
        argument: {
            inputs: [
                {
                    type: 'string',
                    key: 'context',
                },
                {
                    type: 'string',
                    key: 'versionName',
                },
            ],
        },
        result: {
            inputs: [],
        },
        filter: {
            projectId: '2',
            serviceId: '15',
        },
        workflowId: '650738ffdeb0799f6b88947e',
        workflowStep: 0,
        createdAt: '2023-09-17T17:35:59.592Z',
        updatedAt: '2023-10-01T17:54:00.834Z',
        stateUpdatedAt: '2023-09-17T17:35:59.618Z',
        lockedAt: '2023-09-17T17:35:59.592Z',
        __v: 2,
    },
    {
        // front
        delays: {
            '1': 120000,
            '2': 600000,
        },
        cronActivity: {
            pending: false,
            frequency: 86400000,
            nextActivity: '2023-10-01T18:05:49.222Z',
            lastActivity: '2023-10-01T17:55:49.118Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '65074c2edeb0799f6b8902d5',
        state: 2,
        bag: {
            inputs: [],
        },
        actionRef: 'WaitForInput',
        nTimes: 0,
        argument: {
            inputs: [
                {
                    type: 's3',
                    key: 'code',
                },
                {
                    type: 'string',
                    key: 'context',
                },
                {
                    type: 'string',
                    key: 'versionName',
                },
            ],
        },
        result: {
            inputs: [],
        },
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        workflowId: '65074c2edeb0799f6b8902bd',
        workflowStep: 0,
        createdAt: '2023-09-17T18:57:50.961Z',
        updatedAt: '2023-10-01T17:55:49.334Z',
        stateUpdatedAt: '2023-09-17T18:57:50.982Z',
        lockedAt: '2023-09-17T18:57:50.961Z',
        __v: 2,
    },
    {
        delays: {
            '1': 120000,
            '2': 120000,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:57:13.972Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fe6deb0799f6b885ba2',
        state: 6,
        bag: {},
        actionRef: 'CreateCdn',
        nTimes: 0,
        argument: {
            s3BucketUrl: 'wbce-front.s3.eu-west-3.amazonaws.com',
            CallerReference: '16949698305860.6890334844433776',
            s3SubFolderName: '65072fa49a1c266e137bafcc',
            srcDomainName: 'test.preview.webcapsule.io',
        },
        result: {
            distributionId: 'E3IDLURL1VTO36',
            domainName: 'd1g71i6817tg84.cloudfront.net',
        },
        filter: {
            projectId: '2',
            serviceId: '13',
            context: 'preview',
        },
        workflowId: '65072fabdeb0799f6b885ae7',
        workflowStep: 2,
        createdAt: '2023-09-17T16:57:10.608Z',
        updatedAt: '2023-09-18T16:57:14.030Z',
        stateUpdatedAt: '2023-09-18T16:57:14.014Z',
        lockedAt: '2023-09-17T16:57:10.608Z',
        __v: 3,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:07:51.347Z',
            lastActivity: '2023-10-01T17:57:51.226Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '65074c2edeb0799f6b8902bd',
        state: 2,
        bag: {
            currentStepIndex: 1,
            actions: {
                '65074c2edeb0799f6b8902d5': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1],
            lastWaitId: '65074c2edeb0799f6b8902d5',
        },
        actionRef: 'GenericFrontMainCycle',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '13',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        workflowId: '65072fa49a1c266e137baff7',
        workflowStep: 4,
        createdAt: '2023-09-17T18:57:50.932Z',
        updatedAt: '2023-10-01T17:57:51.459Z',
        stateUpdatedAt: '2023-09-17T18:57:50.957Z',
        lockedAt: '2023-09-17T18:57:50.932Z',
        __v: 1,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:07:51.460Z',
            lastActivity: '2023-10-01T17:57:51.368Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '65072fa49a1c266e137baff7',
        state: 2,
        bag: {
            currentStepIndex: 9,
            actions: {
                '65074c2edeb0799f6b8902bd': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1, 3, 5, 7, 9],
            isRollBackPossible: true,
        },
        actionRef: 'GenericFrontMainWorkflow',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '13',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        createdAt: '2023-09-17T16:56:04.223Z',
        updatedAt: '2023-10-01T17:57:51.555Z',
        stateUpdatedAt: '2023-09-17T18:57:50.929Z',
        lockedAt: '2023-09-17T16:56:04.225Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': 180000,
        },
        cronActivity: {
            pending: false,
            frequency: 60000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:56:08.022Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fa6deb0799f6b8859ab',
        state: 6,
        bag: {},
        actionRef: 'createWBCERepo',
        nTimes: 0,
        argument: {
            repositoryName: '13',
        },
        result: {
            gitId: '28fe7918-02ec-483d-bb9b-aa754626fef5',
            projectName: '13',
        },
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        workflowId: '65072fa5deb0799f6b885965',
        workflowStep: 0,
        createdAt: '2023-09-17T16:56:06.782Z',
        updatedAt: '2023-09-18T16:56:08.073Z',
        stateUpdatedAt: '2023-09-18T16:56:08.060Z',
        lockedAt: '2023-09-17T16:56:06.782Z',
        __v: 3,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:56:10.349Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fa5deb0799f6b885965',
        state: 6,
        bag: {
            currentStepIndex: -1,
            actions: {},
            stepsHistory: [0, 1],
            isRollBackPossible: true,
        },
        actionRef: 'createWBCERepoAndSave',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '13',
        },
        result: [{}],
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        workflowId: '65072fa49a1c266e137baff7',
        workflowStep: 0,
        createdAt: '2023-09-17T16:56:05.772Z',
        updatedAt: '2023-09-18T16:56:10.432Z',
        stateUpdatedAt: '2023-09-18T16:56:10.412Z',
        lockedAt: '2023-09-17T16:56:05.773Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': 120000,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T16:57:09.776Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fabdeb0799f6b885b00',
        state: 6,
        bag: {},
        actionRef: 'CreateCdn',
        nTimes: 0,
        argument: {
            s3BucketUrl: 'wbce-front.s3.eu-west-3.amazonaws.com',
            CallerReference: '16949697717310.22520549109327015',
            s3SubFolderName: '65072fa49a1c266e137bafca',
            srcDomainName: 'test.staging.webcapsule.io',
        },
        result: {
            distributionId: 'E13VJUBZVBNV04',
            domainName: 'd3w0yk6b5he8rk.cloudfront.net',
        },
        filter: {
            projectId: '2',
            serviceId: '13',
            context: 'staging',
        },
        workflowId: '65072fabdeb0799f6b885ae7',
        workflowStep: 0,
        createdAt: '2023-09-17T16:56:11.782Z',
        updatedAt: '2023-09-18T16:57:09.942Z',
        stateUpdatedAt: '2023-09-18T16:57:09.925Z',
        lockedAt: '2023-09-17T16:56:11.782Z',
        __v: 3,
    },
    {
        delays: {
            '1': 120000,
            '2': 120000,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T18:57:50.059Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 12,
        },
        _id: '65072feadeb0799f6b885be6',
        state: 6,
        bag: {},
        actionRef: 'CreateCdn',
        nTimes: 0,
        argument: {
            s3BucketUrl: 'wbce-front.s3.eu-west-3.amazonaws.com',
            CallerReference: '16949698341700.1221392963878083',
            s3SubFolderName: '65072fa49a1c266e137bafcb',
            srcDomainName: 'test.prod.webcapsule.io',
        },
        result: {
            distributionId: 'E35FR84CQLBVK0',
            domainName: 'd29g6hfr2fve85.cloudfront.net',
        },
        filter: {
            projectId: '2',
            serviceId: '13',
            context: 'prod',
        },
        workflowId: '65072fabdeb0799f6b885ae7',
        workflowStep: 4,
        createdAt: '2023-09-17T16:57:14.174Z',
        updatedAt: '2023-09-18T18:57:50.143Z',
        stateUpdatedAt: '2023-09-18T18:57:50.127Z',
        lockedAt: '2023-09-17T16:57:14.174Z',
        __v: 39,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-18T18:57:51.356Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '65072fabdeb0799f6b885ae7',
        state: 6,
        bag: {
            currentStepIndex: -1,
            actions: {},
            stepsHistory: [0, 1, 2, 3, 4, 5],
            isRollBackPossible: true,
        },
        actionRef: 'InitFront',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '13',
        },
        result: [{}],
        filter: {
            projectId: '2',
            serviceId: '13',
        },
        workflowId: '65072fa49a1c266e137baff7',
        workflowStep: 2,
        createdAt: '2023-09-17T16:56:11.697Z',
        updatedAt: '2023-09-18T18:57:51.432Z',
        stateUpdatedAt: '2023-09-18T18:57:51.416Z',
        lockedAt: '2023-09-17T16:56:11.697Z',
        __v: 17,
    },
    {
        // directus
        delays: {
            '1': 120000,
            '2': 180000,
        },
        cronActivity: {
            pending: false,
            frequency: 60000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-12T08:01:33.964Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '64fec95a26323c8e1bbfbd35',
        state: 6,
        bag: {},
        actionRef: 'createWBCERepo',
        nTimes: 0,
        argument: {
            repositoryName: '14',
        },
        result: {
            gitId: '6406217c-79b0-4b2d-bdf0-9fff77137dbf',
            projectName: '14',
        },
        filter: {
            projectId: '2',
            serviceId: '14',
        },
        workflowId: '64fec95826323c8e1bbfbcfb',
        workflowStep: 0,
        createdAt: '2023-09-11T08:01:30.936Z',
        updatedAt: '2023-09-12T08:01:34.339Z',
        stateUpdatedAt: '2023-09-12T08:01:34.280Z',
        lockedAt: '2023-09-11T08:01:30.936Z',
        __v: 3,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '4022-02-01T00:00:00.000Z',
            lastActivity: '2023-09-12T08:01:36.924Z',
        },
        repeat: {
            '4': 0,
            '5': null,
        },
        nExecutions: {
            '4': 1,
            '5': 0,
        },
        _id: '64fec95826323c8e1bbfbcfb',
        state: 6,
        bag: {
            currentStepIndex: -1,
            actions: {},
            stepsHistory: [0, 1],
            isRollBackPossible: true,
        },
        actionRef: 'createWBCERepoAndSave',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '14',
        },
        result: [{}],
        filter: {
            projectId: '2',
            serviceId: '14',
        },
        workflowId: '64fec956201c64ba83ed08cc',
        workflowStep: 0,
        createdAt: '2023-09-11T08:01:28.936Z',
        updatedAt: '2023-09-12T08:01:37.195Z',
        stateUpdatedAt: '2023-09-12T08:01:37.185Z',
        lockedAt: '2023-09-11T08:01:28.936Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': 600000,
        },
        cronActivity: {
            pending: false,
            frequency: 86400000,
            nextActivity: '2023-10-01T18:23:49.698Z',
            lastActivity: '2023-10-01T18:13:49.636Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '64fed1f7ea70d759712e1a05',
        state: 2,
        bag: {
            inputs: [],
        },
        actionRef: 'WaitForInput',
        nTimes: 0,
        argument: {
            inputs: [
                {
                    type: 'string',
                    key: 'context',
                },
                {
                    type: 'string',
                    key: 'versionName',
                },
            ],
        },
        result: {
            inputs: [],
        },
        filter: {
            projectId: '2',
            serviceId: '14',
        },
        workflowId: '64fed1f7ea70d759712e19ed',
        workflowStep: 0,
        createdAt: '2023-09-11T08:38:15.328Z',
        updatedAt: '2023-10-01T18:13:49.699Z',
        stateUpdatedAt: '2023-09-11T08:38:15.353Z',
        lockedAt: '2023-09-11T08:38:15.328Z',
        __v: 2,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:24:01.261Z',
            lastActivity: '2023-10-01T18:14:01.159Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '64fec956201c64ba83ed08cc',
        state: 2,
        bag: {
            currentStepIndex: 9,
            actions: {
                '64fed1f7ea70d759712e19ed': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1, 3, 5, 7, 9],
            isRollBackPossible: true,
        },
        actionRef: 'DirectusMainWorkflow',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '14',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '14',
        },
        createdAt: '2023-09-11T08:01:26.202Z',
        updatedAt: '2023-10-01T18:14:01.318Z',
        stateUpdatedAt: '2023-09-11T08:38:15.274Z',
        lockedAt: '2023-09-11T08:01:26.206Z',
        __v: 9,
    },
    {
        delays: {
            '1': 120000,
            '2': null,
        },
        cronActivity: {
            pending: false,
            frequency: 600000,
            nextActivity: '2023-10-01T18:24:00.823Z',
            lastActivity: '2023-10-01T18:14:00.710Z',
        },
        repeat: {
            '4': 0,
            '5': 0,
        },
        nExecutions: {
            '4': 0,
            '5': 0,
        },
        _id: '64fed1f7ea70d759712e19ed',
        state: 2,
        bag: {
            currentStepIndex: 1,
            actions: {
                '64fed1f7ea70d759712e1a05': {
                    state: 0,
                    result: {},
                    index: 0,
                },
            },
            stepsHistory: [1],
            lastWaitId: '64fed1f7ea70d759712e1a05',
        },
        actionRef: 'DirectusMainCycle',
        nTimes: 0,
        argument: {
            projectId: '2',
            serviceId: '14',
        },
        result: {},
        filter: {
            projectId: '2',
            serviceId: '14',
        },
        workflowId: '64fec956201c64ba83ed08cc',
        workflowStep: 4,
        createdAt: '2023-09-11T08:38:15.280Z',
        updatedAt: '2023-10-01T18:14:00.932Z',
        stateUpdatedAt: '2023-09-11T08:38:15.325Z',
        lockedAt: '2023-09-11T08:38:15.280Z',
        __v: 1,
        logs: [
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
            {
                message:
                    '{"actionId":"64a4493960f6c47210ae18df","actionRef":"ResolveAction","filter":{"projectId":"64a448b560f6c47210ae142a","serviceId":"64a448b560f6c47210ae142e"},"level":"info","message":"cron début dactivité","scriptId":28817,"timestamp":"2023-07-09T17:22:58.526Z"}',
                timestamp: 1688905180691,
            },
        ],
    },
    {
        _id: 'action2',
        state: 2,
        stateUpdatedAt: Date(),
        bag: {},
        actionRef: 'CreateCdn',
        workflowId: 'action2',
        nTimes: 0,
        locked: false,
        lockedAt: Date(),

        repeat: {},

        nExecutions: {
            4: 1,
            3: 1,
        },

        cronActivity: {
            pending: false,
            lastActivity: Date(),
            nextActivity: Date(),
            frequency: 1600,
        },

        updatedAt: Date(),
        createdAt: Date(),
        filter: {
            serviceId: '12',
        },

        logs: ['etape 1', 'etape 2 - en pause'],
    },
    {
        _id: 'action3',
        state: 2,
        stateUpdatedAt: Date(),
        bag: {},
        actionRef: 'WaitToDeploy',
        workflowId: 'action3',
        nTimes: 0,
        locked: false,
        lockedAt: Date(),

        repeat: {},

        nExecutions: {
            4: 1,
            3: 1,
        },

        cronActivity: {
            pending: false,
            lastActivity: Date(),
            nextActivity: Date(),
            frequency: 1600,
        },

        updatedAt: Date(),
        createdAt: Date(),
        filter: {
            serviceId: '12',
            context: 'prod',
        },

        logs: ['etape 1.5', 'etape 2.5 - en pause'],
    },
];

const repos = {
    gitlab: [
        {
            provider: 'gitlab',
            name: 'Test',
            url: 'test.com/.git',
            description: 'This is a test',
            gitId: '1',
            projectName: 'T1',
        },
        {
            provider: 'gitlab',
            name: 'Angular',
            url: 'test2.com/.git',
            description: 'Second test',
            gitId: '2',
            projectName: 'T2',
        },
    ],
};

export const db = {
    userProfile: userProfile,
    context: context,
    currentUser: currentUser,
    projects: projects,
    userInfo: userInfo,
    deployments: deployments,
    actions: actions,
    repos: repos,
    roles: roles,
};

@Injectable()
export class MockedApiService implements ApiServiceInterface {
    apiUrl: string;

    dbLocal: any = {};

    constructor() {
        this.dbLocal = db;
    }

    whoAmI() {
        return of(this.dbLocal.currentUser);
    }

    whoAmIKeycloak() {
        return of({
            projects: this.dbLocal.projects,
            userInfo: this.dbLocal.userInfo,
        });
    }

    cancelDnsUpdateAction() {
        return of([]);
    }

    ApiServiceInterface() {
        return of([]);
    }

    cancelRequest() {
        return of([]);
    }

    changeServicesDomainConfig() {
        return of([]);
    }

    removeDomain() {
        return of([]);
    }

    setAsMainDomain() {
        return of([]);
    }

    addNewDomain() {
        return of([]);
    }

    getMergeRequestsFromList() {
        return of([]);
    }

    mergeBranchWithCommit() {
        return of([]);
    }

    inviteUserKeycloak() {
        return of([]);
    }

    getS3UrlForFileInput() {
        return of([]);
    }

    getDnsUpdateAction;

    connect() {
        return of([]);
    }

    sendS3FileFromPrePost;

    getRepos(provider: string) {
        let repos = [];
        if (this.dbLocal.repos[provider]) repos = this.dbLocal.repos[provider];

        return of(repos).pipe(delay(200));
    }

    getUsersProfile(ids: string[]) {
        const usersProfile: any[] = ids.map((id) => this.getProfilePicture(id));
        return of(usersProfile).pipe(delay(10));
    }

    listUsersKeycloak(): Observable<Object> {
        return of(users).pipe(delay(500));
    }

    getProfilePicture(id: any) {
        let profile = new AvatarGenerator();
        profile.text(this.dbLocal.userProfile[id].letter);
        profile.bgColor(this.dbLocal.userProfile[id].color);
        return {
            _id: id,
            picture: profile.generate(),
        };
    }

    getBranchesOAuth(repo: GitRepo): Observable<any> {
        return of([
            { name: 'master' },
            { name: 'main' },
            { name: 'test' },
            { name: 'xyz' },
            { name: 'prod' },
        ]);
    }

    getBranches(service: Service): Observable<any> {
        return of([
            { name: 'master' },
            { name: 'main' },
            { name: 'test' },
            { name: 'xyz' },
            { name: 'prod' },
        ]);
    }

    getTechno(repo: GitRepo): Observable<{ id: any; back: any; front: any }> {
        return of({ id: 2, back: true, front: false }).pipe(
            delay(0),
            tap((techno: { id; back; front }) => {
                repo.techno = Techno.constructFromDb(techno);
            })
        );
    }

    addService(repo: GitRepo) {
        return of({ _id: 12 }).pipe(
            delay(2000),
            tap((managedService: { _id }) => {
                repo.managedService = managedService;
            })
        );
    }

    changeDomain(project: Project, domainName: string) {
        return of('');
    }

    getCommits(service: Service, context: String) {
        let deployments = this.dbLocal.deployments.find((d) => {
            let use =
                d.serviceId == service._id &&
                d.context.toLowerCase() == context.toLowerCase();
            return use;
        }); // type
        let commits = [];
        if (deployments) commits = deployments.commits;
        return of([...commits]).pipe(delay(200));
    }

    getActionLog(action: Action) {
        let actionLog = [];
        let actionLocal = this.dbLocal.actions.find(
            (a) => a._id === action._id
        );
        if (actionLocal.logs) actionLog = actionLocal.logs;

        return of(log).pipe(delay(200));
    }

    getActions(service: Service) {
        if (!service) return of(this.dbLocal.actions).pipe(delay(200));
        const actions = this.dbLocal.actions.filter((a) => {
            return a.filter?.serviceId == service._id; //type
        });

        return of(actions).pipe(delay(200));
    }

    sendInputs(action: Action, inputs: { [key: string]: string }) {
        const currentProjectId = this.dbLocal.projects.findIndex(
            (project) => project._id == action.filter.projectId
        );
        const currentServiceId = this.dbLocal.projects[
            currentProjectId
        ].services.findIndex(
            (service) => service._id == action.filter.serviceId
        );

        let deploymentTemplate: DeploymentTemplate = {
            ctxtToDeploy: inputs.context,
            createdAt: Date(),
            message: inputs.versionName,
            commitId: 'New',
            author: {},
        };
        let service =
            this.dbLocal.projects[currentProjectId].services[currentServiceId];
        let serviceCtxt = service.getContextDoc(inputs.context);
        serviceCtxt.commits.push(deploymentTemplate);

        let deploymentId = this.dbLocal.deployments.findIndex((deployment) => {
            return (
                deployment.serviceId == action.filter.serviceId &&
                deployment.context === inputs.context
            );
        });
        let deployment = this.dbLocal.deployments[deploymentId];
        deployment.commits.unshift(deploymentTemplate);

        return of('ok').pipe(delay(200));
    }

    watchAction(action: Action) {
        return of('ok').pipe(delay(500));
    }

    deploy(service: Service, commit: Commit, context: string) {
        let deploymentIndex = this.dbLocal.deployments.findIndex(
            (d) => d.serviceId === service._id
        );
        let deployment = this.dbLocal.deployments[deploymentIndex];
        let commitId = deployment.commits.findIndex(
            (d) => d.commitId === commit.commitId
        );
        let commits = deployment.commits.map((d) => {
            if (d.ctxtToDeploy) {
                delete d.ctxtToDeploy;
            }
            return d;
        });
        commits[commitId]['ctxtToDeploy'] = context;
        this.dbLocal.deployments[deploymentIndex] = deployment;
        return of(deployment).pipe(delay(200));
    }

    getAccounts() {
        return of(accounts).pipe(delay(0));
    }

    getProject(project: Project) {
        return of({ projects: project, userInfo: {} }).pipe(delay(0));
    }

    addProject(
        projectName: string,
        stack: StackData,
        gitProvider: GitProvider
    ) {
        let project = newProject;
        project.projectName = projectName;
        this.dbLocal.projects.push(newProject);
        return of({}).pipe(delay(0));
    }

    generateReport(service_id: string, context: contexts): Observable<any> {
        let report = {
            _id: '13',
            createdAt: Date(),
        };
        let index = this.dbLocal.projects[1].services.findIndex(
            (service) => service._id == service_id
        );
        if (index > -1) {
            this.dbLocal.projects[1].services[index]
                .getContextDoc(context)
                .reports.push(report);
        }
        return of(report).pipe(delay(0));
    }

    reportUrlForIframe(
        service_id: string,
        context: contexts,
        report_id: string
    ) {
        const urlGoogle = `https://googlechrome.github.io/lighthouse/viewer/?psiurl=https%3A%2F%2Fwww.google.com%2F&strategy=mobile&category=performance&category=accessibility&category=best-practices&category=seo&category=pwa&utm_source=lh-chrome-ext`;

        switch (report_id) {
            case '12':
                return urlGoogle;
            case '13':
                return urlGoogle;

            default:
        }
        return ``;
    }

    updateAccess(role: any) {
        let index = this.dbLocal.projects[1].roles.findIndex(
            (r) => r.userId == role.userId
        );
        this.dbLocal.projects[1].roles[index] = role;
        return of('ok').pipe(delay(0));
    }

    // merge actions
    getDiffBranch(
        service: Service,
        context: contexts,
        origin: string,
        destination: string
    ) {
        return of('ok').pipe(delay(0));
    }

    mergeBranch(
        service: Service,
        context: contexts,
        origin: string,
        destination: string
    ) {
        return of('ok').pipe(delay(0));
    }

    mergeCherryPickBranch(
        service: Service,
        context: contexts,
        origin: string,
        destination: string,
        commitId: string
    ) {
        return of('ok').pipe(delay(0));
    }

    getGitWeburl(service: Service, branchName?: string) {
        return of('ok').pipe(delay(0));
    }

    getMergeRequestsList(service: Service, destination: string) {
        return of([]).pipe(delay(0));
    }

    getMergeRequestsListFrom(service: Service, destination: string) {
        return of([]).pipe(delay(0));
    }

    getConfigManagerToolUrl(service?: Service, context?: contexts) {
        return of().pipe(delay(0));
    }

    getUrl(service: Service, context: contexts) {
        return of('https://google.com').pipe(delay(0));
    }

    getTemplates() {
        return of(template).pipe(delay(0));
    }

    getTechnos() {
        const technos: Techno[] = Array.from(
            defaultTechnos,
            ([name, value]) => value
        );
        return of(technos).pipe(delay(0));
    }
}
