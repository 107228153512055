import { environment } from '../../environments/environment';
import { AuthService } from '../service/auth.service';
import { KeycloakServiceContext } from './../service/keycloak.service';

export function initializeKeycloak(keycloak: KeycloakServiceContext, auth : AuthService) : () => Promise<void> {
  return () =>
    keycloak.init({
      config: {
        url: `${environment.keycloak.protocol}://${environment.keycloak.domain}${environment.keycloak.port ? ':'+environment.keycloak.port : ''}`,
        realm: 'webcapsule',
        clientId: 'wbce-front'
      },
      initOptions: {
       onLoad: 'check-sso',
      // flow: 'implicit',
       checkLoginIframe: true
       //silentCheckSsoRedirectUri:window.location.origin + '/assets/silent-check-sso.html',
      }
    }).then(() => auth.setKeycloakInit(true))
    .catch(e => auth.setKeycloakInit(false, e));
}


