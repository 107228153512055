import { environment } from "src/environments/environment"
import { technos } from "../enums/technos"
import { StackData, typeService } from "../models/stack"

// Waiting for API
const templatesStacksProd : StackData[] = [
  /* {
     id : 1,
     name : "Build Your Own stack",
     description : "Blank project add your services later",
     picture : "blank",
     content : []
   },*/
   {
     id : 2,
     name : "Private No Code Stack",
     description : "Front-end and Back-end",
     picture : "standard",
     label : "entreprise plan",
     content :[ {
       type : typeService.CMS,
       techno : technos.XANO
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 3,
     name : "Basic Internal Tools",
     description : "Front-end and Back-end",
     picture : "standard",
     label : "open source",
     content : [ {
         type : typeService.CMS,
         techno : technos.DIRECTUS
       },
       {
         type : typeService.FRONT,
         techno : technos.PLASMIC
       },
       {
         type : typeService.DATABASE,
         techno : technos.POSTGRESQL
       }]
   },
   {
     id : 4,
     name : "Web App",
     description : "All tools for a complete Web App",
     picture : "app",
     label : "open source",
     content :[ {
       type : typeService.CMS,
       techno : technos.DIRECTUS
     },
     {
       type : typeService.FRONT,
       techno : technos.PLASMIC
     },
     {
       type : typeService.WORKFLOW,
       techno : technos.N8N
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 5,
     name: "Marketing Web",
     description: "Marketing growth stack",
     picture: "crm",
     content :[ {
       type : typeService.CMS,
       techno : technos.STRAPI
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   }
   ,
   {
     id : 6,
     name : "Mobile App",
     description : "Usefull for creating Web App. and Mobile App.",
     picture : "phone",
     preview: true,
     content :
     [ {
       type : typeService.CMS,
       techno : technos.SUPABASE
     },
     {
       type : typeService.FRONT,
       techno : technos.FLUTTERFLOW
     }]
   }
   ,
   {
     id : 7,
     name : "AI",
     description : "Build your own Chat-GPT",
     picture : "robot",
     preview: true,
     content :[ {
       type : typeService.CMS,
       techno : technos.DIRECTUS
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.WORKFLOW,
       techno : technos.LLAMA
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 8,
     name: "Data Visualization",
     description: "A lightweight tool for querying your data",
     picture: "data",
     preview: true,
     content :[ {
       type : typeService.CMS,
       techno : technos.METABASE
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   }
 ]

 const templatesStacksMock : StackData[] = [
   {
     id : 1,
     name : "Web App",
     description : "All tools for a complete Web App",
     picture : "app",
     content : [ {
       type : typeService.CMS,
       techno : technos.DIRECTUS
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.WORKFLOW,
       techno : technos.N8N
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   } ,
   {
     id : 2,
     name : "AI",
     description : "Build your own Chat-GPT",
     picture : "robot",
     content :[ {
       type : typeService.CMS,
       techno : technos.DIRECTUS
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.WORKFLOW,
       techno : technos.LLAMA
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 3,
     name : "Mobile App",
     description : "Usefull for creating Web App. and Mobile App.",
     picture : "phone",
     content :  [ {
       type : typeService.CMS,
       techno : technos.SUPABASE
     },
     {
       type : typeService.FRONT,
       techno : technos.FLUTTERFLOW
     }]
   },
   {
     id : 4,
     name : "Basic Internal Tools",
     description : "Front-end and Back-end",
     picture : "standard",
     content : [ {
       type : typeService.CMS,
       techno : technos.DIRECTUS
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 5,
     name: "Marketing Web",
     description: "Marketing growth stack",
     picture: "crm",
     content : [ {
       type : typeService.CMS,
       techno : technos.STRAPI
     },
     {
       type : typeService.FRONT,
       techno : technos.WEWEB
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   },
   {
     id : 6,
     name: "Data Visualization",
     description: "A lightweight tool for querying your data",
     picture: "data",
     content : [ {
       type : typeService.CMS,
       techno : technos.METABASE
     },
     {
       type : typeService.DATABASE,
       techno : technos.POSTGRESQL
     }]
   }


 ]

 export const template = environment.mock ? templatesStacksMock : templatesStacksProd
