import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from './service/auth.service';
import { IconRegistryService } from './service/icon-registry.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'app';
    loading = true;

    private iconRegistryService = inject(IconRegistryService);
    private auth = inject(AuthService);

    constructor() {}

    ngOnInit() {
        this.iconRegistryService.addDefaultIcons();

        if (this.auth.keycloakInit) {
            const loadingImage = document.querySelector('.loading-image'); //remove loading picture
            if (loadingImage) {
                loadingImage.classList.add('hide');
            }
        }
    }
}
