import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatListModule } from '@angular/material/list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { initializeKeycloak } from './init/keycloak-init.factory';
import {  KeycloakAngularModule } from 'keycloak-angular';
import { KeycloakServiceContext } from 'src/app/service/keycloak.service';
import { ApiInterceptor } from './intereceptor/api-interceptor';
import { AuthService } from './service/auth.service';
import { environment } from 'src/environments/environment';
import { MockedApiService } from './service/api.service-mock';
import { MainApiService } from './service/api.service-main';
import { API_SERVICE_TOKEN } from './service/api.service';


@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatListModule,
        MatIconModule,
        AppRoutingModule,
        SharedComponentsModule,
        KeycloakAngularModule], providers: [
          {
            provide: API_SERVICE_TOKEN,
            useClass: environment.mock ? MockedApiService : MainApiService
          },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakServiceContext, AuthService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

// KeycloakService
export class AppModule {
  public matIconRegistry=inject(MatIconRegistry);

  constructor() {
	  this.matIconRegistry.registerFontClassAlias ('fontawesome', 'fa');
    this.matIconRegistry.registerFontClassAlias('brands', 'fab');
	}
}
