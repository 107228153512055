import { HttpHandler, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retry, retryWhen, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { errorCodes } from '../enums/error-codes';
import {
    Alert,
    AlertCode,
    AlertService,
    AlertType,
} from '../service/alert.service';
import { AuthService } from '../service/auth.service';
import { KeycloakServiceContext } from '../service/keycloak.service';
import {
    InformationDialogComponent,
    informationType,
} from '../shared-components/session-expired-dialog/information-dialog.component';

export const maxRetries = 3;
export const delayMs = 2000;

@Injectable()
export class ApiInterceptor {
    private dialog = inject(MatDialog);
    private keycloak = inject(KeycloakServiceContext);
    alertService = inject(AlertService);
    auth = inject(AuthService);

    constructor() {}

    // add token from keycloak module
    conditionallyUpdateToken() {
        if (this.keycloak.isTokenExpired(240)) {
            console.log('Access token expired refreshing.');
            return this.keycloak.updateToken(240).then(() => {
                console.log('Access token refreshed.');
            });
        }
        return Promise.resolve();
    }

    isUrlExcluded({ method, url }, { urlPattern, httpMethods }) {
        const httpTest =
            httpMethods.length === 0 ||
            httpMethods.join().indexOf(method.toUpperCase()) > -1;
        const urlTest = urlPattern.test(url);
        return httpTest && urlTest;
    }

    handleRequestWithTokenHeader(httpRequest, next) {
        return this.keycloak.addTokenToHeader(httpRequest.headers).pipe(
            mergeMap((headersWithBearer) => {
                const kcReq = httpRequest.clone({ headers: headersWithBearer });
                return next.handle(kcReq);
            })
        );
    }

    errorTrapping(err) {
        let message = '';
        let code = AlertCode.API_ERROR;
        let type = AlertType.ERROR;
        let status = err.status;

        if (err.status === 0) {
            // server/connection/CORS
            message =
                'Network connection error occurred. Please check your internet connection or retry later.';
            type = AlertType.ERROR_PERMANENT;
            this.alertService.addAlert(new Alert(message, code, type));
        } else {
            let error = err.error;

            if (error) {
                if (typeof error === 'string') {
                    try {
                        error = JSON.parse(error);
                    } catch (parseError) {}
                }

                if (typeof error === 'object') {
                    if (error.status) {
                        status = error.status;
                    } else if (error.code) {
                        status = error.code;
                    }

                    message = error.message;
                    if (error.error) error.error.message;
                } else {
                    status = err.status;
                    message = error;
                }
            } else {
                status = err.status;
                message = err.message;
            }

            switch (status) {
                case 500: //server down
                    // permanent alert
                    message =
                        'API server error, reload page in few minutes. If the site is still not working, contact us';
                    type = AlertType.ERROR_PERMANENT;
                    break;
                case errorCodes.NOTHING_TO_COMMIT: //
                    message = 'No modifications detected, nothing to commit';
                    type = AlertType.WARNING;
                    break;
                case errorCodes.INVALID_SESSION: //
                    this.openInformationDialog();
                    return;
                case errorCodes.NO_JOB_LOGS:
                    return; // no error only change display component
                default:
                    break;
            }

            this.alertService.addAlert(new Alert(message, code, type));
        }
    }
    openInformationDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true; // Disable closing on backdrop click
        dialogConfig.hasBackdrop = true; // Enable backdrop
        dialogConfig.panelClass = ['wbce-dialog-pane'];
        dialogConfig.data = informationType.SESSION_EXPIRED;

        this.dialog.open(InformationDialogComponent, dialogConfig);
    }

    intercept(
        httpRequest: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {
        if (
            httpRequest.url.includes(environment.api.domain) ||
            httpRequest.url.includes(environment.keycloak.domain)
        ) {
            return combineLatest([
                this.conditionallyUpdateToken(),
                of(this.keycloak.isLoggedIn()),
            ])
                .pipe(
                    mergeMap(([_, isLoggedIn]) => {
                        return isLoggedIn
                            ? this.handleRequestWithTokenHeader(
                                  httpRequest,
                                  next
                              )
                            : next.handle(httpRequest);
                    })
                )
                .pipe(
                    retry({
                        count: maxRetries,
                        delay: (error, retryCount) => {
                            console.log('retry');
                            // Vérifiez que c'est une erreur réseau (status === 0)
                            if (error.status === 0 && retryCount < maxRetries) {
                                return of(null).pipe(delay(delayMs));
                            } else {
                                // Si ce n'est pas une erreur réseau ou si le nombre de retries est dépassé, propager l'erreur
                                return throwError(() => error);
                            }
                        },
                    })
                )
                .pipe(
                    tap({
                        error: (err) => {
                            return this.errorTrapping(err);
                        },
                    })
                );
        } else {
            return next
                .handle(httpRequest)
                .pipe(
                    retryWhen((error) =>
                        error.pipe(
                            mergeMap((error, index) => {
                                if (index < maxRetries && error.status == 0) {
                                    //only when network error retry before error
                                    return of(error).pipe(delay(delayMs));
                                }

                                throw error;
                            })
                        )
                    )
                )
                .pipe(
                    tap({
                        error: (err) => {
                            return this.errorTrapping(err);
                        },
                    })
                );
        }
    }
}
